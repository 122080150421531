import React, { useEffect, useLayoutEffect, useState } from "react"
import { useFormik } from "formik"
import axios from "axios"
import { navigate } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import Video from "../components/video"

import {
  StyledSignUp,
  StyledSignUpSection,
  StyledSignUpHeader,
  StyledSignUpLede,
  StyledSignUpForm,
  StyledSignUpFormFieldset,
  StyledSignUpFigure,
  ErrorMessage
} from "../styledComponents/signUp"
import { 
  StyledLabel,
  StyledInput,
  StyledLabelHeader,
  StyledFormElementWrapper,

} from "../styledComponents/contact"
import { StyledButton } from "../styledComponents/button"

const SignUp = ({isNewsletterSignUpCookieSet, createSignUpCookie, image}) => {
  const [token, setToken] = useState("") // store token
  const [isSuccessMessage, setIsSuccessMessage] = useState(false) // manage is success message state
  const [messageSent, setMessageSent] = useState(false) // manage sent message state
  const [isFormValid, setIsFormValid] = useState(false)

  const interests = [
    {
        "id": "7961a4c0bb",
        "name": "Level 1"
    },
    {
        "id": "8b83af9712",
        "name": "Mastery"
    },
    {
        "id": "30f4b48196",
        "name": "Corporate"
    },
    {
        "id": "e43c4a5e38",
        "name": "Personal Consultation"
    },
    {
        "id": "8da686a466",
        "name": "Free Trial"
    },
    {
        "id": "c66760abc3",
        "name": "Contact form"
    }
  ]

  const freeVideoPlay = () => {
    const el1 = document.querySelector('#firstName')

    el1.focus()
  }

  const validate = values => {
    const errors = {}
    if (!values.firstName) {
      errors.firstName = "Required"
    }
    if (!values.emailAddress) {
      errors.emailAddress = "Required"
    } else if (
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.emailAddress)
    ) {
      errors.emailAddress = "Invalid email address"
    }

    Object.keys(errors).length ? setIsFormValid(false) : setIsFormValid(true)
    return errors
  }

  // this effect function authenticates our subcriber user to get a token
  useEffect(() => {
    axios({
      method: "post",
      url: `https://adminwp.kaarmibeing.com/wp-json/jwt-auth/v1/token`,
      data: {
        username: `contactform`, // provide a user credential with subscriber role
        password: `%BE7ISvKz@jjFTnQNwr4Pxgn`,
      },
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then(response => {
        setToken(response.data.token)
      })
      .catch(error => console.error("Error", error))
  }, [])

  // use useFormik hook using object destructuring assignment to extract helpful methods
  const {
    handleChange,
    isSubmitting,
    values,
    handleSubmit,
    errors,
    touched,
    
  } = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      emailAddress: "",
    },
    validate,
    onSubmit: (
      {
        firstName,
        emailAddress,
      },
      { setSubmitting, resetForm }
    ) => {
      setSubmitting(true)
      createSignUpCookie()
      // bodyFormData.set("emailaddress", emailAddress)
      let fName, lName
      if(values.firstName.trim().indexOf(' ') != -1){
        fName = firstName.split(' ').slice(0, -1).join(' ')
        lName = firstName.split(' ').slice(-1).join(' ')
      } else {
        fName = firstName
        lName = ''
      }
      axios.post("/.netlify/functions/formHandler" , {firstName:fName , lastName:lName ,emailAddress:emailAddress.toLowerCase() , interest: interests[4]})

      const bodyFormData = new FormData()
      bodyFormData.set("firstName", values.firstName)
      bodyFormData.set("emailAddress", emailAddress)
      // here we sent
      axios({
        method: "post",
        url: `https://adminwp.kaarmibeing.com/wp-json/contact-form-7/v1/contact-forms/827/feedback`,
        data: bodyFormData,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      })
        .then(response => {
          // actions taken when submission goes OK    
          resetForm()
          setSubmitting(false)
          setMessageSent(true)
          setIsSuccessMessage(true)
          navigate("/free-trial")
        })
        .catch(error => {
          // actions taken when submission goes wrong
          setSubmitting(false)
          setMessageSent(true)
          setIsSuccessMessage(false)
        })
    },
  })
  return (
    <StyledSignUp isNewsletterSignUpCookieSet={isNewsletterSignUpCookieSet}>
      <StyledSignUpSection>
        <StyledSignUpHeader>
          Watch our free video
          </StyledSignUpHeader>
          
          <StyledSignUpLede>
          Access a taster of our course and see how KaarmiBeing will improve your health, wellbeing and state of mind. Please add your email to watch this free video.
          </StyledSignUpLede>
          <StyledSignUpFigure>
            {/* <GatsbyImage image={image}  /> */}
            <img src="/video-placeholder-no-control-bar.png" onClick={() => freeVideoPlay()} />
          </StyledSignUpFigure>
                    
          <StyledSignUpForm onSubmit={handleSubmit}>
            <StyledSignUpFormFieldset>
              <StyledFormElementWrapper>
                <StyledLabel htmlFor="firstName">
                  <StyledLabelHeader
                    required
                    isEmpty={errors.firstName && touched.firstName}
                  >
                    First Name
                  </StyledLabelHeader>
                  <StyledInput
                    id="firstName"
                    name="firstName"
                    type="text"
                    onChange={handleChange}
                    value={values.firstName}
                    placeholder="Your name"
                    required
                    autoFocus
                  />
                </StyledLabel>
                {errors.firstName && touched.firstName ? (
                  <span className="error-message">{errors.firstName}</span>
                ) : null}

                <StyledInput
                  id="outlined-email-input"
                  label="Email"
                  type="email"
                  name="emailAddress"
                  autoComplete="email"
                  placeholder="your@email.com"
                  onChange={handleChange}
                />
                <StyledButton 
                  label="Submit" 
                  type="submit"
                  disabled={isSubmitting || !isFormValid}
                  >
                  Show me the video!
                </StyledButton>
              </StyledFormElementWrapper>
              <p>We will never share your information with anyone.</p>
            </StyledSignUpFormFieldset>
            {messageSent && !isSuccessMessage && (
              <ErrorMessage>
                <p>Something went wrong please try again.</p>
              </ErrorMessage>
            )}
          </StyledSignUpForm>
      </StyledSignUpSection>
    </StyledSignUp>
  )
}
export default SignUp
