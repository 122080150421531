import React, { useState, useLayoutEffect } from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import SignUp from "../components/signUp"
import HeroBanner from "../components/HeroBanner"
import Video from "../components/video"
import EventList from "../components/eventList";
import ProgramPromo from "../components/programPromo"
import { HeroVideo } from "../styledComponents/heroBanner"
import { VideoWrapper } from "../styledComponents/video"
const FreeTrial = ({ data }) => {
  const autoPlay = true
  const [isNewsletterSignUpCookieSet, setIsNewsletterSignUpCookieSet] = useState(true)
  const [isFreeVideoParamSet, setisFreeVideoParamSet] = useState(true)
  let displayNavBorder = false
  if(!isNewsletterSignUpCookieSet) {
    displayNavBorder = true
  } 
  const checkIfSignUpCookieExists = () => {
    document.cookie
      .split(";")
      .some(item => item.trim().startsWith("free-trial="))
      ? setIsNewsletterSignUpCookieSet(true)
      : setIsNewsletterSignUpCookieSet(false)
  }

  const createSignUpCookie = () => {
    document.cookie = `free-trial=true; path=/; host=${
      process.env.GATSBY_DOMAIN_NAME
      }; ${process.env.GATSBY_SECURE_COOKIE ? "secure;" : ""}`
      setIsNewsletterSignUpCookieSet(true)
  }

  const checkIfFreeTrialQueryExists = () => {
    const urlParams = new URLSearchParams(window.location.search)
    const freeTrialParam = urlParams.get('fs')
    if(freeTrialParam){
      createSignUpCookie()
    }
  }

  const checkIfFreeVideoQueryExists = () => {
    const urlParams = new URLSearchParams(window.location.search)
    const freeVideoParam = urlParams.get('v')
    if(freeVideoParam){
      setisFreeVideoParamSet(freeVideoParam)
    }
  }

  
  useLayoutEffect(() => {
    if (typeof window !== "undefined" || window.document) {
      checkIfSignUpCookieExists()
      checkIfFreeTrialQueryExists()
      checkIfFreeVideoQueryExists()
      return
    }
  }, [])

  return (
    <>
    <Layout displayNavBorder={displayNavBorder} hasHero={false}>
      <SEO title={data.wpPage.title} />
      {isNewsletterSignUpCookieSet && 
      <>
      <HeroBanner page="free-trial">
        <HeroVideo>
          <VideoWrapper>
            <Video videoURL={isFreeVideoParamSet} placeholder="/video-placeholder-no-control-bar.png" autoPlay/>
          </VideoWrapper> 
        </HeroVideo>
      </HeroBanner>
      
      <EventList data={data} page="Level 1" />
      <ProgramPromo /> 
      </>|| 
      <SignUp 
      isNewsletterSignUpCookieSet={isNewsletterSignUpCookieSet} 
      createSignUpCookie={createSignUpCookie} />
      }
    </Layout>
    </>
  )
}

export default FreeTrial

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    wpPage(slug: { eq: "free-trial" }) {
      title
      content
      featuredImage {
        node {
          altText
          localFile {
            publicURL
            childImageSharp {
              gatsbyImageData(
                width: 975
                quality: 100
                placeholder: TRACED_SVG
                breakpoints: [320, 720, 1024, 1600]
              )
            }
          }
        }
      }
      heroSection {
        kaarmibeingHeroBackground {
            uri
            localFile {
              publicURL
            }
        }
        kaarmibeingHeroHeading
        kaarmibeingHeroLedeText
        kaarmibeingHeroPrimaryCta {
          title
          url
        }
      }
      OurProgram {
        programData {
          programContent
          programHeader
          programLede
          svg {
            localFile {
              publicURL
            }
          }
        }
      }
      eventsData {
        events {
          date
          time
          timezone
          fieldGroupName
          location
          price
          shortDescription
        }
      }
    }

    ourEvents: wpPage(slug: { eq: "homepage" }) {
      eventsData {
        events {
          date
          time
          timezone
          fieldGroupName
          location
          price
          shortDescription
        }
      }
    }
  }
`
