import styled from "styled-components"
import breakpoints from "./breakpoints"
import { regular11, bold24, bold32, bold48, bold64 } from "./typography"
import { StyledForm, StyledFieldset } from "../styledComponents/contact"
import { baseGridStyles } from "./base"
const StyledSignUp = styled.div`
  /* background-color: var(--light-blue-darkest); */
`

const StyledSignUpSection = styled.section`
  ${baseGridStyles}
  align-items: center;
  flex-direction: column;
  text-align: left;
  z-index: 1;
  padding: 1.6rem 1.6rem 1.6rem;
  @media screen and (min-width: ${breakpoints.sm}px) {
    padding: 6.8rem calc((24 / 720) * 100vw) 2.4rem;
    align-items: flex-start;
  }
  @media screen and (min-width: ${breakpoints.md}px) {
    text-align: center;
    padding: 6rem calc((24 / 1024) * 100vw) 3.2rem;
    align-items: center;
    
  }
`

const StyledSignUpHeader = styled.h2`
  margin-top: 0;
  grid-column: span 4;
  ${bold24};
  @media screen and (min-width: ${breakpoints.sm}px) {
    ${bold32}; 
  }
  @media screen and (min-width: ${breakpoints.md}px) {
    ${bold48}; 
    grid-column: span 12;
    text-align: center;
    
  }
  @media screen and (min-width: ${breakpoints.l}px) {
    ${bold64};
  }
`

const StyledSignUpLede = styled.p`
  text-align: left;
  grid-column: span 4;

  @media screen and (min-width: ${breakpoints.sm}px) {
    grid-column-start: 1;
    grid-column-end: 7;
  }
  @media screen and (min-width: ${breakpoints.md}px) {
    margin-top: 2.4rem;
    text-align: center;
    grid-column: span 12;
  }
  @media screen and (min-width: ${breakpoints.l}px) {
    grid-column-start: 4;
    grid-column-end: 10;
  }
`

const StyledSignUpFigure = styled.figure`
  margin: 0;
  padding: 0;
  grid-column: span 4;
  @media screen and (min-width: ${breakpoints.md}px) {
    margin-top: 2.4rem;
    text-align: left;
    grid-column-start: 1;
    grid-column-end: 8;
  }
  /* margin-left: -1.6rem; */
  /* width: calc(100vw - 3.2rem); */
  /* min-width: 28.8rem; */
  /* @media screen and (min-width: ${breakpoints.sm}px) {
    margin-left: 0;
    width: auto;
  } */
`

const StyledSignUpFigureCaption = styled.figcaption`
  margin: 0;
  padding: 0;
  
`

const StyledSignUpVideoPlaceHolder = styled.img`
  /* grid-column: span 4; */
  /* max-width: -webkit-fill-available; */
`

const StyledSignUpForm = styled(StyledForm)`
  
  display: grid;
  grid-column: span 4;
  grid-gap: 1.6rem;
  grid-template-columns: repeat(4,1fr);
  grid-row-gap: 1.6rem;
  padding: 0;

  @media screen and (min-width: ${breakpoints.sm}px) {
    display: block;
    grid-column-start: 5;
      grid-column-end: 7;
  }
  @media screen and (min-width: ${breakpoints.md}px) {
    grid-column-start: 8;
    grid-column-end: 13;
  }

  label span{
    display: none;
  }

  input[type="email"],
  input[type="text"] {
    
    border: 1px solid var(--grey-light);
    padding: 1.6rem 1.6rem;

    &:focus{
      border: 1px solid var(--grey-mid);
      
    }
  }

  button {
    width: 100%;
    &:disabled {
      opacity: 0.4;
    }

  }

  p { 
    grid-column: span 4;
    ${regular11}
    text-align: center;
    @media screen and (min-width: ${breakpoints.sm}px) {
      margin-top: 0.8rem;
    }
  }
`

const StyledSignUpFormFieldset = styled(StyledFieldset)`
  ${baseGridStyles}
  padding: 0;
  @media screen and (min-width: ${breakpoints.sm}px) {
    padding: 0;
    width:100%;
    display:block;
  }
  div {

    grid-column: span 4;

    @media screen and (min-width: ${breakpoints.sm}px) {
      display: block;
    }
  }
`

export {
  StyledSignUp,
  StyledSignUpSection,
  StyledSignUpHeader,
  StyledSignUpLede,
  StyledSignUpForm,
  StyledSignUpFormFieldset,
  StyledSignUpVideoPlaceHolder,
  StyledSignUpFigure,
  StyledSignUpFigureCaption
}
