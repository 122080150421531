import styled from "styled-components"
import breakpoints from "./breakpoints"
import { Section } from "../styledComponents/section"

const ProgramPromoSection = styled(Section)`

    h2, p {
        text-align: center;
    }

    figure {
        grid-column: span 4;
        margin-bottom: 1.6rem;
        @media screen and (min-width: ${breakpoints.sm}px) {
            grid-column: span 3;
        }
    }
`

const ProgramPromoSectionInfo = styled.div`
    grid-column: span 4;
    display: flex;
    flex-direction: column;

    @media screen and (min-width: ${breakpoints.sm}px) {
        grid-column: span 3;
        order: 1;
        p {
            &:first-child{
                margin-bottom: 1.6rem;
            }
        }
    }

    @media screen and (min-width: ${breakpoints.md}px) {
        grid-column: span 6;
        order: 1;
        
    }
`

export { 
    ProgramPromoSection,
    ProgramPromoSectionInfo
}
