import React from "react"
import {
    SectionLede,
} from "../styledComponents/section"

import { 
    ProgramPromoSection,
    ProgramPromoSectionInfo
} from "../styledComponents/programPromo"
import { StyledLinkButton } from "../styledComponents/button";

const ProgramPromo = props => {
  return (
    <>

        <ProgramPromoSection>
            <ProgramPromoSectionInfo>
                <h2>KaarmiBeing Level 1</h2>
                <figure>
                    <img src="/kb-background-2.jpg" alt="" width="640" height="430" />
                </figure>
                <SectionLede>The KaarmiBeing workshops provide beginners and advanced level learning opportunities about how to improve wellbeing and overall quality of life.</SectionLede>
                <StyledLinkButton to="/level-1#overview">Find out more</StyledLinkButton>
            </ProgramPromoSectionInfo>
    
            <ProgramPromoSectionInfo>
                <h2>Personal consultation</h2>
                <figure>
                    <img src="/static/eb6c66488b0ff837198e756fd2d7811b/kaarmibeing-personal-consultation.jpg" alt="" width="640" height="430" />
                </figure>    
                <SectionLede>We can help you get where you want to be.</SectionLede>
                <StyledLinkButton to="/personal-consultation#overview">Find out more</StyledLinkButton>
            </ProgramPromoSectionInfo>
        </ProgramPromoSection>

        
    </>
  )
}

export default ProgramPromo
